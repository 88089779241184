

export const Data = [
    {
     title: "Home",
     path: "/",
     className:"BHome"
    },
    {
     title: "About Me",
     path: "Second",
     className:"BSecond"
    },
    {
        title: "Projects",
        path: "Projects",
        className:"BProjects"
       },
    {
     title: "TechStack",
     path: "TechStack",
     className:"BTechStack"
    }
   ];